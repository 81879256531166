/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.liquid').imgLiquid();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var didScroll;

        var lastScrollTop = 0;
        var delta = 5;
        var header = $('header.topbar');
        var navbarHeight = header.outerHeight();
        var menuRotate = true;

        $('.variation select').customSelect();
        $('.orderby').customSelect();
        $('.wppp-select').customSelect();

        $('.tabs .tab').click(function(){
          var num = $(this).attr('data-tab');

          $('.tabs .tab').removeClass('active');
          $(this).addClass('active');

          $('.tab_content').removeClass('active');
          $('.tab_content.' + num).addClass('active');
        });

        $('.show-menu-items').click(function(){
            $('#menu-headmenu').slideToggle();
           $('.show-menu-items').toggleClass('menu-border');
            setTimeout(
                function()
                {
                    $('.show-menu-items-icon').toggleClass('fa-bars');
                    $('.show-menu-items-icon').toggleClass('fa-times');
                }, 150);


            if ($(window).width() < 500) {
              $('.prim_nav_container').toggleClass('box-shadow');
            }

            // if ($(window).width() < 499) {
            //     $('.show-menu-items').toggleClass('alignRight');
            // }

           if(menuRotate === true){
               rotateImage(180);
               menuRotate = false;

           }else{
               rotateImage(-180);
               menuRotate = true;
           }
        });

          $('.filter-tabs').click(function(){
              $('.filter-tabs').removeClass('tab_active');
              $(this).addClass('tab_active');
              var filterid =  $(this).attr('id');
              $('.filter_page').removeClass('filter-active');
              $('.filter_page').addClass('displayNone');
              $('.' + filterid).removeClass('displayNone');
              $('.' + filterid).addClass('filter-active');
              $('.slick-slider').slick('refresh');
              $('ul.products .product').matchHeight();
              $('.product .name').matchHeight();
              $('.product .price').matchHeight();
          });

          $(document).ready(pageSliders());

          function pageSliders(){
              $('.filter_page').addClass('displayNone');
              $('.page0').removeClass('displayNone');
          }


          function rotateImage(degree) {
              $('.show-menu-items-icon').animate({  transform: degree }, {
                  step: function(now,fx) {
                      $(this).css({
                          '-webkit-transform':'rotate('+now+'deg)',
                          '-moz-transform':'rotate('+now+'deg)',
                          'transform':'rotate('+now+'deg)'
                      });
                  }
              });
          }

        $('body, html').scrollTop(0);
        $('.page-overlay').fadeOut();

        $('.filter_page .product').matchHeight();
        $('.web-reviews .item').matchHeight();
        $('ul.products .product').matchHeight();
        $('.product .name').matchHeight();
        $('.product .price').matchHeight();


        $('.overlay .more').click(function() {
            var status = $(this).attr('data-status');
            var content = $(this).parent().parent();
            var less = $(this).attr('data-less');
            var more = $(this).attr('data-more');

            if (status === 'close') {
                $(this).attr('data-status', 'open');
                $(this).html(less + ' <i class="fa fa-angle-double-up" aria-hidden="true"></i>');
                content.css('height', 'auto');
                content.css('height', content.outerHeight() + 25);
            } else {
                $(this).attr('data-status', 'close');
                $(this).html(more + ' <i class="fa fa-angle-double-down" aria-hidden="true"></i>');
                content.css('height', '170px');
            }
        });

        $('.media-large-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: '.media-thumbnail-slider'
        });

        $('.media-thumbnail-slider').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            asNavFor: '.media-large-slider',
            dots: false,
            focusOnSelect: true,
            arrows: true,
            prevArrow: '<button class="PrevArrow"></button>',
            nextArrow: '<button class="NextArrow"></button>'
        });

          $('.filter_page').slick({
              dots: false,
              arrows: false,
              infinite: false,
              speed: 300,
              rows: 2,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                  {
                      breakpoint: 1024,
                      settings: {
                          slidesToShow: 4,
                          slidesToScroll: 4,
                      }
                  },
                  {
                      breakpoint: 912,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                      }
                  },
                  {
                      breakpoint: 790,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                      }
                  },
                  {
                      breakpoint: 580,
                      settings: {
                          rows: 10,
                          slidesToShow: 1,
                          slidesToScroll: 1
                      }
                  }
                  // You can unslick at a given breakpoint now by adding:
                  // settings: "unslick"
                  // instead of a settings object
              ]
          });

        // $('.featured .filter .filter_pages').slick({
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     arrows: false,
        //     dots: false,
        //     asNavFor: '.featured .filter .tabs'
        // });
        //
        // $('.featured .filter .tabs').slick({
        //     slidesToShow: 8,
        //     slidesToScroll: 1,
        //     variableWidth: true,
        //     asNavFor: '.featured .filter .filter_pages',
        //     dots: false,
        //     focusOnSelect: true,
        //     arrows: false,
        // });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

    $('.review-bar').slick({
        dots: false,
        infinite: false,
        speed: 300,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    // $('.footer-usps').slick({
    //     dots: false,
    //     arrows: false,
    //     infinite: false,
    //     speed: 300,
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    // });

    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.top-container').outerHeight();

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down

            if ($(window).width() > 500) {
                $('.top-container').slideUp();
            }

            if ($(window).width() > 994) {
                setTimeout(
                    function()
                    {
                        $('.cart-bottom').fadeIn();
                    }, 300);
            }
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                // $('header').removeClass('mintop').addClass('nav-down');
                $('.top-container').slideDown();
                if ($(window).width() > 994) {
                    $('.cart-bottom').fadeOut();
                }
            }
        }

        lastScrollTop = st;
    }

    // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      if($(document).width > 994){
          $('#menu-headmenu').addClass('opacity0');
      }


      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

    $('.product-name-2').matchHeight();
    $('.feat-prod').matchHeight();

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


